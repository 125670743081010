const FormReleaseStyle = () => ({
  textbox: {
    background: '#f3f3f3',
    border: '2px solid #eeeeee',
    webkitBorderRadius: '3px',
    mozRorderRadius: '3px',
    borderRadius: '3px',
    padding: '10px 5px',
    width:'98%',
    paddingRight: '34%',
    margin: '7px 0',
    
  },

  containerFluid: {
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
    "@media (max-width : 550px)":{
      width:'89% !important',
      marginTop:'-1%'
    }
    
  },
  row: {
    marginRight: '-15px',
    marginLeft: '-15px',
    "@media (max-width : 480px)":{
      marginRight: '0px',
    marginLeft: '0px',
    }
  },
  starBgImage: {
    minHeight: '300px',
    "@media (max-width : 480px)":{
      minHeight: '0px',
    }
  },
  container: {
    '@media (min-width: 576px)': {
      maxWidth: `540px`,
    },
    '@media (min-width: 768px)': {
      maxWidth: `750px`,
    },
    '@media (min-width: 992px)': {
      maxWidth: `970px`,
    },
    '@media (min-width: 1200px)': {
      maxWidth: `1170px`,
    },
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },

  textCenter: {
    textAlign: 'center',
  },

  rowflex: {
    '@media (max-width: 480px)': {
      flexDirection: 'column',
    },
    display: 'inline-flex',
    marginBottom: '25px',
    marginRight: '-15px',
    marginLeft: '-15px',
  },
  containerStyle: {
    '@media (max-width: 576px)': {
      width: 'auto',
    },
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',

    marginLeft: 'auto',
    marginTop: '-60 px',
    // display: "none",
    width: '92%',
  },
  logoPosition: {
    maxWidth: `none`,
    marginLeft: '-2%',
    marginTop: '1%',
    "@media (max-width : 550px)":{
      width:'31%'
    }
  },
  formHeaderStyle: {
    width: '100%',
    margin: '2% auto',
    textAlign: 'center',
    color: '#1c3785',
    overflow: 'hidden',
    fontSize: '1.8em',
    fontWeight: '900',
    lineHeight: '1.4',
    textTransform: 'uppercase',
    '& p': {
      marginBottom: `0px`,
    },
    "@media (max-width : 1025px)":{
      fontSize:'1.4em',
      marginTop:'3%'
    },
    "@media (max-width : 768px)":{
      fontSize:'1.3em',
      marginTop:'3%',
      
    },
    "@media (max-width : 580px)":{
      fontSize:'9px',
      marginTop:'3% !important',
      marginBottom:'3%'
    }
  },
  testStyle: {
    fontSize: '0.9em',
    fontWeight: '900',
    color: '#1c3785',
    "@media (max-width : 768px)":{
    overflow: 'hidden',
    fontSize: '17px',
    fontWeight: '500',
    lineHeight: '1.4',
    textTransform: 'uppercase',
    fontFamily: "'Roboto', sans-serif"
    }

  },
  testStyle1: {
    fontFamily: "'Roboto', sans-serif",
    fontWeight: '400',
    fontSize: '16px',
    textTransform: 'none',
    color: '#1c3785',
    "@media (max-width : 1025px)":{
      fontSize: '14px',
      },
    "@media (max-width : 768px)":{
      fontFamily: "'Roboto', sans-serif",
      fontSize: '14px',
      fontWeight: '500',
      color: '#1c3785'
      }
    
  },
  bgmImagestyle: {
    marginTop: '-20px',
    width: '90%',
    height: 'auto',
    padding: ' 1% 5%',
    margin: '0',

    overflow: 'hidden',
    clear: 'both',
    paddingBottom:'0%',
    "@media (max-width : 480px)":{
      width: '100%',
      marginTop: '0px',
      padding: ' 0%',
    }
  },
  completebodystyle:{
  width: '100%',
   background: '#eaeaea' ,
  marginTop: '-20%' ,
  "@media (max-width:912px)":{
    marginTop: '-22%' 

  },
  "@media (max-width:679px)":{
    marginTop: '3%' 

  }
    },
  formRootContainer: {
    width: '28%',
    padding: '0% 1% 0% 1%',
    float: 'right',
    marginRight: '-6.5%',
    background: 'rgba(255, 255, 255, 0.55)',
    fontFamily: 'Roboto',
    borderBottomRightRadius: '5px',
    marginTop:'-36%',
    marginLeft:'55%',
    position:'absolute',
    paddingBottom:'1%',
    borderBottomLeftRadius: '5px',
    boxShadow: '0px 6px 8px #888888',
    "@media (max-width : 1025px)":{
      width: '40%',
      marginTop:'-34%',
    marginLeft:'45%',
    },
    "@media (max-width : 768px)":{
      width: '43%',
      marginTop:'-43%'
    },
    "@media (max-width : 550px)":{
      width: '39%',
      marginTop:'-33%',
    marginLeft:'45%',
    },
    "@media (max-width : 520px)":{
      width: '40%',
      marginTop:'-33%',
    marginLeft:'45%',
    },
    "@media (max-width : 500px)":{
      width: '100%',
      padding: '0% 0% 0% 0%',
    float: 'left',
    position:'relative',
    marginRight: '0%',
    paddingLeft:'3%',
    paddingRight:'1%',
    marginTop:'0%',
    marginLeft:'0%',

    }
  },
  
  privacytextstyle:{
  fontWeight: '400',
  fontFamily: "'Roboto', sans-serif",
  paddingBottom: '4%',
  fontSize:'12px',
  lineHeight:'17px',
  color:'black',
  "@media (max-width : 768px)":{
    fontSize: '11px'
  },
  "@media (max-width : 580px)":{
    fontSize:'11px',
    // marginTop:'10% !important'
  },
  "@media (max-width : 480px)":{
    fontSize:'11px',
    // marginTop:'10% !important'
  }
  },
  formContainer: {
    fontSize: '20px',
    fontFamily: 'Roboto',
    textAlign: 'center',
    textTransform: 'uppercase',
    padding: '3% 1% 3% 1%',
    color: '#fff',
    background: ' #1c3785',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',
    display: 'block',
    width: '98%',
    
  },
  headerposition:{
    marginTop:'2%'
  },
  bannerImagePosition:{
    marginTop:'2%'
  },
  buttonStyle: {
    borderRadius: '8px',
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    color: '#ffffff',
    cursor:'pointer',
    padding: '8px',
    width: '100%',
    background: '#1c3785',
    fontSize: '16px',
    '@media (max-width: 500px)': {
      fontSize: '14px',
    },
  },
  boxSizePosition: {
    width: '31%',
    height: 'auto',
    float: 'left',
    margin: '1%',
    border: '1px #C9C9C9 solid',
    background: '#fff',
    "@media (max-width : 480px)":{
      width: '100%',
      marginTop:'14%'
      // marginTop:'10% !important'
    }
  },
  brand: {
    // maxWidth: `none`,
    // widows: '5%',577
    width:'35%',
    height: 'auto',
    borderRadius: '50%',
    marginTop: '-13%',
  },

  brandHeader: {
    margin: '0',
    padding: '7% 5%',
    width: ' 100%',
    textAlign: 'center',
    color: '#1c3785',
    fontFamily: 'Roboto',
    fontSize: 'auto',
    fontWeight: 'bold',
    "@media (max-width : 550px)":{
      fontSize:'17px',
    }
    
  },
  brandContent: {
    margin: '0',
    padding: '4% 5%',
    width: '90%',
    textAlign: 'center',
    color: '#525252',
    fontFamily: 'Roboto',
    fontSize: 'auto',
    fontWeight: '400',
    lineHeight: '20px',
    paddingBottom:'11%'
  },
  line: {
    borderColor: '#9a9a9a',
    borderStyle: 'solid',
    borderWidth: '1px',
    float: 'left',
    marginTop: '2%',
    width: '100%',
    background: 'none',
    textAlign: 'center',
  },
  listRoot: {
    marginTop: '5.5%',
    width: '100%',

    background: '#f3f3f3',
    clear: 'both',
    overflow: 'hidden',
  },
  listHeader: {
    padding: '2% 0%',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '26px',
    fontWeight: '400',
    width: '100%',
    textTransform: 'uppercase',
    background: '#1c3785',
    color: '#fff',
  },
  listStyle: {
    marginTop: '2%',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 'normal',
    color: 'black',
  },
  footerContent: {
    width: '100%',
    height: 'auto',
    padding: '2% 10%',
    overflow: 'hidden',
    background: '#f8f8f8',
    fontSize: '0.6em',
    textAlign: 'center',
    color: '#525252',
    borderTop: '1px solid #CBCBCB',
    borderBottom: '1px solid #CBCBCB',
    lineHeight: `12px`,
    fontWeight: `400`,
    "@media (max-width : 550px)":{
      padding: '4% 10%',
      }
  },
  footer: {
    width: '100%',
    margin: '0',
    padding: '1% 0',
    textAlign: 'center',
    fontSize: '0.7em',
    background: '#eaeaea',
    color: '#94969e',
    fontWeight: '900',
  },
});
export default FormReleaseStyle;
