import React from 'react';
import logo from 'ps-assets/img/logo.png';
import InstantBuyLayout from "ps-components/Layout/InstantBuyLayout.jsx";
import PropTypes from "prop-types";
import root from "window-or-global";
import {
  withStyles,
} from '@material-ui/core/styles';
import FormReleaseStyle from 'ps-assets/jss/FormReleaseStyle.jsx';
import bannerImage from 'ps-assets/img/free-quote-bg.jpg';
import single from 'ps-assets/img/Icon-1.png';
// import Card from "components/Card/Card";
// import CardHeader from "components/Card/CardHeader";
// import CardBody from "components/Card/CardBody";
import family from 'ps-assets/img/Icon-2.png';
import old from 'ps-assets/img/Icon-3.png';
import listIcon from 'ps-assets/img/list-icon.png';
import { landingPageLMSCall } from "util/lmsRequest";
import Hidden from '@material-ui/core/Hidden';
import {  browserWindow } from "util/localStorageHelper";
const HOSPITALCOUNT = process.env.HOSPITALCOUNT;
const validationConditions = {
  name: `^[a-zA-Z .]*$`,
  mobile: `^[6-9][0-9]{9}$`,
  pincode: `^[1-9][0-9]{5}$`,
};

class FormRelease extends React.Component {

  state = {
    name: ``,
    email: ``,
    mobile: ``,
    pincode: ``,
    privacyPolicy: false,
    submitButton: false,
  };

  handleChange = (e) => {
    switch (e.target.id) {
      case `name`:
          this.setState({ [e.target.id]: e.target.value });
        break;
      case `pincode`:
        if (!isNaN(e.target.value) && e.target.value.length <= 6) {
          this.setState({ [e.target.id]: e.target.value });
        }
        break;
      case `mobile`:
        if (!isNaN(e.target.value) && e.target.value.length <= 10) {
          this.setState({ [e.target.id]: e.target.value });
        }
        break;
      case `otpNumber`:
        if (!isNaN(e.target.value) && e.target.value.length <= 6) {
          this.setState({ [e.target.id]: e.target.value, otpError: `` });
        }
        break;
      default:
        this.setState({ [e.target.id]: e.target.value });
        break;
    }
  };

  handlePrivacyPolicy = () => {
    this.setState({
      privacyPolicy: !this.state.privacyPolicy,
    });
  };

  sendToLMS = async () => {
    try{
    let queryParams = decodeURIComponent(browserWindow.location.search);
      queryParams = queryParams.split(`?`)[1];
    let response = await landingPageLMSCall(
      this.state.name,
      this.state.mobile,
      this.state.email,
      this.state.pincode
    );
    response = await response.json();
    sessionStorage.setItem(`prevLandingPage`,`free-quote-for-complete-health`);
    root.open(`${browserWindow.location.pathname}/thank-you?userId=${response.zohoEntityId}${queryParams ? `&${queryParams}` : ``}`, `_self`);
    }
    catch(exception){
      console.error(`except - `,exception);
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      submitButton: true,
    });
    if (this.state.name !== ``) {
      if (this.state.email.search(/^\S+@\S+\.\S+$/) === 0) {
        if (this.state.mobile.search(validationConditions.mobile)==0) {
          if (this.state.privacyPolicy) {
            if (this.state.pincode.search(validationConditions.pincode)==0) {
              this.sendToLMS();
            }
          }
        }
      }
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <InstantBuyLayout noFooter={true} noHeader={true}>
        <div className={classes.container}>
          <img
            src={logo}
            // className={classes.brand}
            className={classes.logoPosition}
            alt='Star Health'
          />
        </div>
        <Hidden smDown>
        <div
          style={{
            backgroundImage: `url(` + bannerImage + `)`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
            marginTop: `0px`,
            paddingBottom:'35%'
          }}
        >
          <div>
            <div className={classes.formHeaderStyle}>
              <p className={classes.testStyle}>
                Trust the expertise and experience of STAR Health in providing health insurance!
              </p>
              <p className={classes.testStyle1}>
                STAR has wide range of policies to meet the health insurance needs of you and your loved ones.
              </p>
            </div>
          </div>
       </div>
       </Hidden>
       <Hidden mdUp>
        <div>
        <div className={classes.headerposition}>
            <div className={classes.formHeaderStyle}>
              <p className={classes.testStyle}>
                Trust the expertise and experience of STAR Health in providing health insurance!
              </p>
              <p className={classes.testStyle1}>
                STAR has wide range of policies to meet the health insurance needs of you and your loved ones.
              </p>
            </div>
          </div>
          <div className={classes.bannerImagePosition}>
            <img src={bannerImage} style={{width:'100%'}} />
          </div>
       </div>
       </Hidden>
       
       <div
            className={`${classes.containerFluid} ${classes.bannerBgline}`}
            // style={{ display: "none" }}
          >
            <div
              className={`${classes.row} ${classes.starBgImage} ${classes.bgmImagestyle}`}
            >
              <div className={classes.formRootContainer}>
                <div>
                  <div>
                    <div>
                      <div className={classes.formContainer}>
                        <p style={{ marginBottom: '15px' }}>
                          <p style={{ fontSize: '1.4em', fontWeight: '500' }}>
                            Fill In Your Details
                          </p>
                          <p style={{ fontSize: '1.6em', fontWeight: '500' }}>
                            {' '}
                            To Get Started
                          </p>
                        </p>
                      </div>
                      <form onSubmit={this.handleSubmit}>
                        <div>
                          <span></span>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            value={this.state.name}
                            onChange={this.handleChange}
                            className={classes.textbox}
                            placeholder="Enter Name"
                          />
                          {this.state.submitButton && this.state.name.length<3 && (
                            <p>Name is not in the required format</p>
                          )}
                        </div>
                        <div>
                          <span></span>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            className={classes.textbox}
                            placeholder="Enter E-mail"
                          />
                          {this.state.submitButton &&
                            this.state.email.search(/^\S+@\S+\.\S+$/) !== 0 && (
                              <p>Please enter a valid email address.</p>
                            )}
                        </div>
                        <div>
                          <span></span>
                          <input
                            type="text"
                            id="mobile"
                            className={classes.textbox}
                            placeholder="Enter Mobile Number"
                            name="mobile"
                            value={this.state.mobile}
                            onChange={this.handleChange}
                          />
                          {((this.state.submitButton && this.state.mobile.length < 10) || (this.state.submitButton && this.state.mobile.search(`^[6-9][0-9]{9}$`) == -1)) && (
                              <p>Enter 10 Digit Phone Number</p>
                            )}
                        </div>
                        <div>
                          <span></span>
                          <input
                            type="text"
                            id="pincode"
                            className={classes.textbox}
                            placeholder="Enter PinCode"
                            name="pincode"
                            value={this.state.pincode}
                            onChange={this.handleChange}
                          />
                          {((this.state.submitButton && this.state.pincode.length < 6) || (this.state.submitButton && this.state.pincode.search(`^[1-9][0-9]{5}$`) == -1)) && (
                              <p>Enter 6 Digit Valid PinCode</p>
                            )}
                        </div>
                        <div>
                          <div className={classes.privacytextstyle}>
                            <input
                              type='checkbox'
                              name='checkbox'
                              title='Please accept terms and conditions'
                              id='checkbox'
                              onClick={this.handlePrivacyPolicy}
                              checked={this.state.privacyPolicy}
                            />{' '}
                            {` `}I have read and agree to the
                            <a
                              href="/static-privacy-policy"
                              target='_blank'
                              style={{ color: 'black',cursor:`pointer`,marginLeft:`3px` }}
                              rel="noopener noreferrer"
                            >
                              <u>privacy policy</u>
                            </a>
                            . I authorize Star Health And Allied Insurance to
                            contact me via email, phone or SMS.
                          </div>
                          {this.state.submitButton && !this.state.privacyPolicy && (
                            <p style={{ textAlign: `center` }}>
                              Please Agree to the Terms
                            </p>
                          )}
                          <div>
                            <div>
                              <button
                                type='submit'
                                id='contact-form-submit'
                                className={classes.buttonStyle}
                              >
                                {' '}
                                Submit{' '}
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        <div className={classes.completebodystyle}>
          <div className={classes.containerStyle}>
            <div className={classes.rowflex} style={{ paddingTop: '5%' }}>
              <div className={classes.boxSizePosition}>
                <div style={{ textAlign: 'center' }}>
                  <>
                    <center>
                      <img
                        src={single}
                        className={classes.brand}
                        alt='Star Health'
                      />
                    </center>
                    <p className={classes.brandHeader}>
                      A comprehensive Protection
                    </p>
                    <p className={classes.brandContent}>
                      As we can’t predict when a health issue can surface, it is always wise to be prepared. You need a holistic product loaded with holistic benefits, just like the Star Comprehensive Insurance Policy.
                    </p>
                  </>
                </div>
              </div>
              <div className={classes.boxSizePosition}>
                <div style={{ textAlign: 'center' }}>
                  <>
                    <center>
                      <img
                        src={family}
                        className={classes.brand}
                        alt='Star Health'
                      />
                    </center>
                    <p className={classes.brandHeader}>
                      Family First
                    </p>
                    <p className={classes.brandContent}>
                      Protect your family members against any health eventualities with a single sum insured. Get Family Health Optima Insurance that comes with a host of features and exclusive benefits.
                    </p>
                  </>
                </div>
              </div>
              <div className={classes.boxSizePosition}>
                <div style={{ textAlign: 'center' }}>
                  <>
                    <center>
                      <img
                        src={old}
                        className={classes.brand}
                        alt='Star Health'
                      />
                    </center>
                    <p className={classes.brandHeader}>
                      Safe and Secure after 60
                    </p>
                    <p className={classes.brandContent}>
                      The best assurance seniors above 60 years can ask for will be good health and reassurance of well-being. Precisely what the Senior Citizens Red Carpet Health Insurance offers. Get it now to feel safe and be secured.
                    </p>
                  </>
                </div>
              </div>
            </div>
            <div className={classes.line}></div>
            <div className={classes.listRoot}>
              <div className={classes.listHeader}>
                When it comes to health insurance we offer the best protection
              </div>
              <div>
                <ul
                  className={classes.listStyle}
                  style={{
                    listStyleImage: `url(` + listIcon + `)`,
                  }}
                >
                  <li style={{ padding: '0.5% 0 0.5% 2%' }}>
                  Best Health Insurer - Outlook Money Awards 2016
                  </li>
                  <li style={{ padding: '0.5% 0 0.5% 2%' }}>
                  Largest Network hospital base of {HOSPITALCOUNT} hospitals
                  </li>
                  <li style={{ padding: '0.5% 0 0.5% 2%' }}>
                  Direct claim settlement without the intervention of TPA
                  </li>
                  <li style={{ padding: '0.5% 0 0.5% 2%' }}>
                  89.9% of all cashless claim approval done within 1hour
                  </li>
                  <li style={{ padding: '0.5% 0 0.5% 2%' }}>
                  Awarded as the Best Health Insurance Provider of the year - Business Today, Money Today Financial Awards 2016-2017
                  </li>
                  <li style={{ padding: '0.5% 0 0.5% 2%' }}>
                  Award of Health Insurance Company of the Year 2017 - Finteleket Insurance Awards 2017.
                  </li>
                  <li style={{ padding: '0.5% 0 0.5% 2%' }}>
                  Pan India presence with more than 640+ Branch Offices
                  </li>
                  {/* <li style={{ padding: '0.5% 0 0.5% 2%' }}>
                    More than 550 Branches PAN INDIA.
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.footerContent}>
          REGISTERED & CORPORATE OFFICE: NO: 1, NEW TANK STREET, VALLUVARKOTTAM
          HIGH ROAD, NUNGAMBAKKAM, CHENNAI - 600034.
          <br />
          CIN NO: L66010TN2005PLC056649.
          <br />
          IRDAI REGISTRATION NO: 129.
          <br />
          INSURANCE IS THE SUBJECT MATTER OF SOLICITATION.
          <br />
          FOR MORE DETAILS ON RISK FACTORS, TERMS AND CONDITIONS PLEASE READ THE
          SALES BROCHURE CAREFULLY BEFORE CONCLUDING A SALE.
          <br />
          IRDAI CLARIFIES TO PUBLIC THAT
          <br />
          * IRDAI OR ITS OFFICIALS DO NOT INVOLVE IN ACTIVITIES LIKE SALE OF ANY
          KIND OF INSURANCE OR FINANCIAL PRODUCTS NOR INVEST PREMIUMS.
          <br />
          * IRDAI DOES NOT ANNOUNCE ANY BONUS.
          <br />
          * PUBLIC RECEIVING SUCH PHONE CALLS ARE REQUESTED TO LODGE A POLICE
          COMPLAINT ALONG WITH DETAILS OF PHONE CALL, NUMBER.
          <br /> SHAI/17-18/Online/LP/BuyOnline/009 | Sales & Services - 044 4674 5800 |
          Website:
          <u>
            <a href='http://www.starhealth.in'>www.starhealth.in</a>
          </u>
          <br />
        </div>
        <div className={classes.footer}>
          Copyright ©2015. Star Insurance. All rights reserved{' '}
        </div>
      </InstantBuyLayout>
    );
  }
}

FormRelease.propTypes = {
  classes: PropTypes.object
}

export default withStyles(FormReleaseStyle)(FormRelease);
